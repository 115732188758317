/* eslint-disable no-underscore-dangle */
import React from 'react';

import QUERY from '../src/queries/page.graphql';
import GENERIC_QUERY from '../src/queries/pageGeneric.graphql';
import Page from '../src/components/Page';
import Blocks from '../src/components/Blocks';
import Navigation from '../src/components/Navigation';
import NavigationMobile from '../src/components/NavigationMobile';
import Meta from '../src/components/Meta';
import HeaderBanner from '../src/components/HeaderBanner';

import {
  PageQuery,
  PageGenericQuery,
  PageGenericFragment,
  PageFragment,
} from '../src/schemas/api';

import api from '../src/utils/api';

export async function getStaticProps() {
  const pageQuery: PageQuery = await api(QUERY, { page: '/' });
  const pageGenericQuery: PageGenericQuery = await api(GENERIC_QUERY);

  return {
    props: {
      pageGeneric: pageGenericQuery.pageGeneric,
      page: pageQuery.page?.items[0],
    },
  };
}

type HomePageProps = {
  page: PageFragment;
  pageGeneric: PageGenericFragment;
};

function Homepage({ page, pageGeneric }: HomePageProps) {
  return (
    <Page generic={pageGeneric}>
      <Meta title={page.title} indexable={page.indexable} />
      <HeaderBanner />
      <Navigation />
      <NavigationMobile />
      <Blocks page={page} />
    </Page>
  );
}

export default Homepage;
