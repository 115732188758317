import React, { memo, useContext } from 'react';
import styled from 'styled-components';
import { useSessionStorage } from 'react-use';

import { PageContext } from './PageProvider';
import MarkdownInline from './MarkdownInline';
import Icon from './Icon';

import TYPO from '../styles/typography';
import COLORS from '../styles/colors';

import BREAKPOINTS from '../styles/breakpoints';

const Container = styled.div`
  padding: 16px 12px;
  background: ${COLORS.brand.light.opacity(0.3)};
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 999;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const Content = styled.div`
  font-size: 14px;
  & svg {
    position: relative;
    top: 0.1em;
  }
  strong {
    color: ${COLORS.brand.regular.css};
    font-weight: 500;
  }
  a {
    font-weight: 500;
    text-decoration: none;
    color: ${COLORS.brand.regular.css};

    &:hover {
      text-decoration: underline;
    }
  }

  ${BREAKPOINTS.max.small`
  ${TYPO.footer}
  `}
`;

const CloseButton = styled.button`
  padding: 2px;
  cursor: pointer;
  color: ${COLORS.black.css};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: ${COLORS.shades.s300.css};
  }
`;

function HeaderBanner() {
  const {
    generic: { headerBanner },
  } = useContext(PageContext);
  const [show, setShow] = useSessionStorage('header_banner', '1');

  if (!headerBanner || !headerBanner.show || show !== '1') {
    return null;
  }

  return (
    <Container>
      <Inner>
        <Content>
          <MarkdownInline>{headerBanner.content}</MarkdownInline>
        </Content>
        <CloseButton onClick={() => setShow('0')} aria-label="Close">
          <Icon name="X" width={12} height={12} />
        </CloseButton>
      </Inner>
    </Container>
  );
}

export default memo(HeaderBanner);
